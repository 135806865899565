import React from 'react'
import Layout from '../components/layout'

const Terms = () => <Layout>
  <div className="py-16 xl:py-36 px-4 sm:px-6 lg:px-8 bg-white overflow-hidden">
    <div className="max-w-max-content lg:max-w-7xl mx-auto">
      <div className="relative z-10 mb-8 md:mb-2 md:px-6">
        <div className="text-base max-w-prose lg:max-w-none">
          <p className="leading-6 text-blue-700 font-semibold tracking-wide uppercase mb-6">Terms and
            Conditions</p>
          <h1
           className="prose prose-lg text-gray-500 mb-6 lg:max-w-none lg:mb-0">The
             privacy policy protects you, the terms and conditions protect me</h1>
        </div>
      </div>
      <div className="relative">
        <div className="relative md:bg-white md:p-6">
          <div className="lg:grid lg:grid-cols-2 lg:gap-6 mb-8">
            <div className="prose prose-lg text-gray-500 mb-6 lg:max-w-none lg:mb-0">
              <h3>Section 1 - Terms</h3>
              <p>By visiting the website, you agree to be bound by these terms and conditions and follow
                any applicable laws and regulations.
              </p><p>
                Don’t share the course with others. I work hard on the classes and deserve to be paid by everyone who takes them.
              </p><p>
                Don't hack my website, we all have better things to do.</p>

              <h3>Section 2 — Use license</h3>
              <p>Want to refer the course to a friend? You can screenshot images to share with friends and
                discuss, no problem.
                This does not mean you own the image though and you do not have permission to use it for
                commercial use, or pass it
                off as your own work. You are making your own waxes now, use those!</p>


              <h3>Section 3 - Price modifications</h3>
              <p>I reserve the right to change my prices. Making a class is hard work! If I feel that
                I need to make more
                money on the classes I'll increase the price.
              </p>

              <h3>Section 4 — Accuracy of account information</h3>
              <p>I'm trusting you to provide me with your correct information. If you provide a wrong email
                address I have no
                way of
                contacting you, so please check your information carefully before
                purchasing.</p>

              <h3>Section 5 - Billing</h3>
              <p>Class fee has to be paid in full before you get access to the videos. There are no refunds.</p>

            </div>
            <div className="prose prose-lg text-gray-500">
              <h3>Section 6 - Termination</h3>
              <p>I reserve the right to terminate your access to the classes at my own discretion, without
                notice or liability.</p>
              <h3>Section 7 — Revisions and errata</h3>
              <p>Look, English is not my first language, so I expect there to by typos from time to time! I'll immediately
                update if I see them (or am told) though. I can also update photos or change the layout of
                the page where I see fit.
              </p><p>
                Basically, the website is a work in progress. Mistakes are made and fixed as quickly as
                possible.</p>
              <h3>Section 8 — Links</h3>
              <p>I might link to other websites from time to time, but I won't continuously check if the
                link is still
                working.
              </p><p>
                Clicking on links that go outside of my website is at your own discretion.</p>
              <h3>Section 9 — Site terms of use modifications</h3>
              <p>I'll update the terms and conditions when I have something new to add. By using the website
                you agree to be bound by the most current version.</p>

              <p>Last update: January 4th, 2022</p>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</Layout>

export default Terms
